import {initExpander} from './chips_expander';
import {trackPageView} from "./tracking/view_tracking";
import {initLinkTracking} from "./tracking/link_tracking";
import {initSlider} from '@otto-ec/nav_patternsson/src/js/slider';
import {send, trackingTypes} from "./tracking/tracking_api";
import {VisibilityTracker} from "./tracking/visibility_tracker";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";
import {tracking} from "./tracking/shared";
import {CategorySuggestionsDoubleSlider, CategorySuggestionsTiles} from "./category_suggestions"

const log = window.o_global.debug.logger("ft-nav.promirnaty.main");

const endOfPageIfVisibleTracker = new VisibilityTracker(
    () => send('tracking.bct.submitEvent', {nav_EndofPage: 'shown'})
);

const saleSliderVisibleTracker = new VisibilityTracker(
    () => send('tracking.bct.submitEvent', {nav_SaleSlider: 'shown'})
);

/**
 *
 *
 *
 *
 *
 *
 */
const initSliderFn = (id, opts) => {
    const sliderContainer = document.querySelector(`#${id} #nav_slider`);
    if (sliderContainer) {
        const sliderType = sliderContainer.dataset.sliderType;
        let sliderElement = sliderContainer.getElementsByClassName("nav_slider")[0];
        let slider = null;
        if (sliderElement) {
            slider = initSlider(sliderElement);
        }
        let kuvoScrolled = false;
        let emptySuggestedScrolled = false;
        let pdpScrolled = false;
        let plSliderScrolled = false;
        initLinkTracking(sliderContainer);
        initExpander(sliderContainer);

        switch (sliderType) {
            case "brandshop-category-card-slider":
                /*                            */
                window.o_global.eventQBus.emit("pattern.carousel.init", `#${id} .js_pl_carousel`);

                function addPositionToContextFeature(feature) {
                    const featureOrderElement = sliderContainer.closest('[data-feature-order]');
                    return (feature.id.startsWith("nav_local_navigation_context")) ? {
                        ...feature,
                        labels: {
                            ...feature.labels,
                            ...(featureOrderElement ? {
                                position: [featureOrderElement.dataset.featureOrder],
                                promo_CardPosition: [featureOrderElement.dataset.featureOrder]
                            } : {})
                        }
                    } : feature;
                }
                /*                                                  */
                function transformViewTracking(tracking) {
                    return tracking.map((command) => {
                        if (command.method === "addFeaturesToPageImpression") {
                            return {
                                method: command.method,
                                features: command.features.map(addPositionToContextFeature)
                            };
                        } else {
                            return command;
                        }
                    })
                }
                track(sliderContainer, "user-action/view-brandshop-card-slider", transformViewTracking);
                /*                                                  */
                function transformClickTracking(tracking) {
                    return tracking.map((command) => {
                        if (command.method === "submitMoveAction") {
                            return {
                                method: command.method,
                                dataContainer: command.dataContainer,
                                action: {
                                    name: command.action.name,
                                    features: command.action.features.map(addPositionToContextFeature)
                                }
                            };
                        } else {
                            return command;
                        }
                    })
                }
                sliderContainer.querySelector(".nav_brandshop-card-show-all_js").addEventListener("click",
                    (event) => track(event.target,
                        "user-action/click-brandshop-card-slider-item",
                        transformClickTracking));
                Array.from(sliderContainer.querySelectorAll(".nav_brandshop-card-slider-item_js")).map((sliderItem) =>
                    sliderItem.addEventListener("click",
                        (event) => track(event.target,
                            "user-action/click-brandshop-card-slider-item",
                            transformClickTracking)))
                break;
            case "pl-category-slider":
                if (slider) {
                    slider.registerScrollCallback(() => {
                        if (!plSliderScrolled) {
                            send('tracking.bct.submitEvent', {
                                nav_SliderActivity: 'slide',
                                nav_SliderType: "mixed_end_of_page_slider",
                            });
                            plSliderScrolled = true;
                        }
                    });
                }
                trackPageView(sliderContainer, sliderType, {});
                endOfPageIfVisibleTracker.observe(sliderContainer);
                break;
            case "similar-sale-slider":
                trackPageView(sliderContainer, sliderType, {});
                saleSliderVisibleTracker.observe(sliderContainer);
                break;
            case "empty-suggest-slider":
                if (slider) {
                    slider.registerScrollCallback(() => {
                        if (!emptySuggestedScrolled) {
                            send('tracking.bct.submitEvent', {
                                    nav_SliderActivity: 'slide',
                                    nav_SliderType: "empty_state_static_deals_slider"
                                });
                            emptySuggestedScrolled = true;
                        }
                    });
                }
                if (!(opts && opts.empty_suggest_notrack)) {
                    window.o_global.events().once('squirrel.search.focus', (evt, data) => {
                        const emptySuggestSlider = sliderContainer.querySelector('.nav_suggest-slider') ||
                              sliderContainer.querySelector('.nav_suggest-slider-bf');
                        track(emptySuggestSlider, 'user-action/open-empty-suggest', (commands) => {
                            commands[0].eventMergeId = evt.eventMergeId;
                            return commands;
                        });
                    });
                }
                window.o_global.eventQBus.emit('ftnav.slider.registered', id);
                break;
            case "pdp-slider":
                const contextElem = sliderContainer.querySelector(tracking.context);
                if (slider) {
                    slider.registerScrollCallback(() => {
                        if (!pdpScrolled) {
                            send('tracking.bct.submitEvent', {
                                nav_SliderActivity: 'slide',
                                nav_SliderType: "pdp_static_deals",
                                product_VariationId: contextElem.dataset.variationId
                            });
                            pdpScrolled = true;
                        }
                    });

                }
                trackPageView(sliderContainer, sliderType, {});
                break;
            case "kuvo-slider":
                if (slider) {
                    slider.registerScrollCallback(() => {
                        if (!kuvoScrolled) {
                            window.o_global.events().once('nav.kuvo.scroll', (evt, data) => {
                                send(trackingTypes.addToEvent, {
                                    nav_SliderActivity: 'slide',
                                    nav_SliderType: "kuvo"
                                }, evt.eventMergeId);
                            });
                            window.o_global.events().emit('tracking.bct.createEventContext', 'nav.kuvo.scroll');
                            kuvoScrolled = true;
                        }
                    });
                }
            window.o_global.events().once('ft9.benefit.sheet.open', (evt, data) => {
                const kuvoSliderElem = sliderContainer.querySelector('.nav_kuvo-container');
                track(kuvoSliderElem, 'user-action/open-kuvo-layer', (commands) => {
                    commands[0].eventMergeId = evt.eventMergeId;
                    return commands;
                });
            }
                                         );
            break;
        default:
            trackPageView(sliderContainer, sliderType, {});
        }
    }
};

window.o_global.eventQBus.on('ftnav.slider.register', (id, opts) => {
    initSliderFn(id, opts);
});

class NavSlider extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        log.debug("Slider initalizing as custom element", {id: this.id});
        initSliderFn(this.id);
    }
}

/*                                                                                    */
/*                                             */
customElements.get("nav-slider") || customElements.define("nav-slider", NavSlider);
customElements.get("nav-category-suggestions-double") || customElements.define("nav-category-suggestions-double", CategorySuggestionsDoubleSlider);
customElements.get("nav-category-suggestions-tiledesign") || customElements.define("nav-category-suggestions-tiledesign", CategorySuggestionsTiles);

/*                                                                             */
window.o_global.eventQBus.emit("ftnav.slider.jsready");
