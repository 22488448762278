export const trackingTypes = {
    tsSend: "tracking.bct.sendEventToTrackingServer",
    moveAction: "tracking.bct.submitMoveAction",
    action: "tracking.bct.submitAction",
    viewTracking: 'tracking.bct.addFeaturesToPageImpression',
    addActionToEvent: 'tracking.bct.addActionToEvent',
    addToEvent: 'tracking.bct.addToEvent',
}

/**
 *
 *
 */
export function send(type, ...args) {
    if (o_global && o_global.eventQBus && typeof o_global.eventQBus.emit === 'function') {
        if (o_global.debug.status().activated) {
            console.debug("promirnaty.trackingDebugLog", type, ...args);
        }
        o_global.eventQBus.emit(type, ...args);
    }
}
