/*                    */

export const tracking = {
    context: '[data-nav-tracking-context="true"]',
    list: '[data-nav-tracking-list="true"]',
    item: '[data-nav-tracking-item="true"]'
}

/**
 *
 *
 *
 *
 *
 */
export function makeContextFeature(elem, options = {}) {
    let {dataset} = elem;
    let contextLabel = 'nav_LocalContext' + dataset.suffix;
    let contextLabelKey = (options.withWkLabel === true) ? "wk." + contextLabel : contextLabel;
    const position = dataset.position ? {position: parseInt(dataset.position)} : {};
    let feature = {
        id: makeContextFeatureId(dataset.featureUuid),
        name: dataset.name ?? 'LocalNavigation-Context',
        status: 'selected',
        labels: {
            [contextLabelKey]: [dataset.navTitle],
            ...((options.sliderType === "category-suggestions-tiledesign" || options.sliderType === "category-suggestions-double")
                ? {
                    promo_Feature: ["true"],
                    nav_LocalCluster: ["recommendation"],
                    nav_PersonalisedCategories: [Array.from(elem.querySelectorAll('[data-nav-personalisedcategories]')).length > 0 ? "true" : "false"]
                }
                : {})
        },
        ...position,
    };

    if (!!dataset.navLevel) {
        feature.labels.nav_LocalHierarchy = [dataset.navLevel];
    }

    if (!!dataset.clusterType) {
        feature.labels.nav_LocalCluster = [dataset.clusterType];
    }

    return feature;
}

export function makeContextFeatureId(featureId) {
    return 'nav_local_context_' + featureId;
}

export function makeListFeatureId(el, idWithPos = true) {
    let idValue = 'nav_local_list_' + el.dataset.featureUuid;
    return (idWithPos === false) ? idValue : idValue + '_' + el.dataset.position;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function makeListFeature(elem, options = {}) {
    let {dataset} = elem;
    let labelKey = 'nav_LocalList' + elem.dataset.suffix;
    let position = parseInt(dataset.position);
    let feature = {
        id: makeListFeatureId(elem, options.idWithPos),
        parentId: 'nav_local_context_' + dataset.featureUuid,
        status: options.status || dataset.status,
        name: dataset.name ?? 'LocalNavigation-List',
        position: position + 1,
        labels: {
            ...((options.sliderType === "category-suggestions-tiledesign" || options.sliderType === "category-suggestions-double")
                ? {
                    nav_LocalCluster: ["recommendation"],
                    nav_PersonalisedCategories: [dataset.navPersonalisedcategories ? "true" : "false"],
                    nav_LocalChipName: [dataset.navTitle]
                }
                : {
                    /*                                     */
                    nav_LocalType: [dataset.navType]
                }),
            [(options.withWkLabel === true ? 'wk.' + labelKey : labelKey)]: [elem.dataset.navTitle]
        }
    };

    if (!!dataset.navLevel) {
        feature.labels.nav_LocalHierarchy = [dataset.navLevel];
    }

    if (!!dataset.clusterType) {
        feature.labels.nav_LocalCluster = [dataset.clusterType];
    }

    return feature;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function makeItemFeature(elem, listPos, options = {}) {
    let {dataset} = elem;
    let idValue = 'nav_local_item_' + dataset.featureUuid;
    let parentIdValue = 'nav_local_list_' + dataset.featureUuid;
    let itemKey = 'nav_LocalItem' + elem.dataset.suffix;
    let position = parseInt(dataset.position);
    let feature = {
        id: (options.idWithPos === false) ? idValue : idValue + '_' + listPos + '_' + position,
        parentId: (options.idWithPos === false) ? parentIdValue : parentIdValue + '_' + listPos,
        name: 'LocalNavigation-Item',
        position: position + 1,
        status: options.status || dataset.status,
        labels: {
            /*                                     */
            nav_LocalType: [dataset.navType],
            [(options.withWkLabel === true ? 'wk.' + itemKey : itemKey)]: [elem.dataset.navTitle]
        }
    };

    if (!!dataset.navLevel) {
        feature.labels.nav_LocalHierarchy = [dataset.navLevel];
    }

    if (!!dataset.clusterType) {
        feature.labels.nav_LocalCluster = [dataset.clusterType];
    }

    if (dataset.variationId) {
        feature.variationId = dataset.variationId;
    }

    return feature;
}
