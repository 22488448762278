import {trackPageView} from "./tracking/view_tracking";
import {makeContextFeatureId, makeListFeatureId, tracking} from "./tracking/shared";
import {personalizeDoubleSlider, personalizeTileDesign} from "./personalization";
import {VisibilityTracker} from "./tracking/visibility_tracker";
import {submitMiniActions} from "@otto-ec/nav_star-track/src/js/star-track";
import {initLinkTracking} from "./tracking/link_tracking";

const log = window.o_global.debug.logger("ft-nav.promirnaty.main");

const categorySuggestionsVisibilityTracker = (featureId) => new VisibilityTracker(
    () => submitMiniActions([{featureId: featureId, status: "visible", action: "scroll"}]));

export class CategorySuggestionsTiles extends HTMLElement {
    constructor() {
        super();
        this.tilesContainer = this.querySelector(`#nav_slider`);
    }

    connectedCallback() {
        log.debug("Initializing category suggestions tiles", {id: this.id});

        initLinkTracking(this.tilesContainer);
        /*                                                                            */
        if (this.tilesContainer.closest('[data-feature-order]')) {
            this.tilesContainer.querySelector(tracking.context).dataset.position =
                this.tilesContainer.closest('[data-feature-order]').dataset.featureOrder;
        }

        const initializeTilesTracking = () => {
            trackPageView(this.tilesContainer, this.tilesContainer.dataset.sliderType, {});
            categorySuggestionsVisibilityTracker(makeContextFeatureId(this.id)).observe(this.tilesContainer);
            let tiles = Array.from(this.tilesContainer.querySelectorAll(".nav_tile"));
            tiles.map(tile => categorySuggestionsVisibilityTracker(makeListFeatureId(tile)).observe(tile));
        }
        /*                                                                                     */
        personalizeTileDesign(this.id, this.tilesContainer).then(initializeTilesTracking, initializeTilesTracking);
    }
}

export class CategorySuggestionsDoubleSlider extends HTMLElement {
    constructor() {
        super();
        this.sliderContainer = this.querySelector(`#nav_slider`);
    }

    connectedCallback() {
        log.debug("Initializing category suggestions double slider", {id: this.id});

        /*                            */
        window.o_global.eventQBus.emit("pattern.carousel.init", `#${this.id} .js_pl_carousel`);

        initLinkTracking(this.sliderContainer);
        /*                                                                            */
        if (this.sliderContainer.closest('[data-feature-order]')) {
            this.sliderContainer.querySelector(tracking.context).dataset.position =
                this.sliderContainer.closest('[data-feature-order]').dataset.featureOrder;
        }

        const initializeDoubleSliderTracking = () => {
            trackPageView(this.sliderContainer, this.sliderContainer.dataset.sliderType, {});
            categorySuggestionsVisibilityTracker(makeContextFeatureId(this.id)).observe(this.sliderContainer);
            let chips = Array.from(this.sliderContainer.querySelectorAll(".nav_double-slider__chip"));
            chips.map(chip => categorySuggestionsVisibilityTracker(makeListFeatureId(chip)).observe(chip));
        }
        /*                                                                                     */
        personalizeDoubleSlider(this.id, this.sliderContainer).then(initializeDoubleSliderTracking, initializeDoubleSliderTracking);
    }
}
